<script setup lang="ts">
import type { Product } from '@shopware-pwa/types';
import {
  getSmallestThumbnailUrl,
  getTranslatedProperty,
} from '@shopware-pwa/helpers-next';

const props = defineProps<{ product: Product }>();

const { product } = toRefs(props);
const { unitPrice, displayFrom } = useProductPrice(product);

const DEFAULT_THUMBNAIL_SIZE = 10;
const imageElement = ref(null);
const { width, height } = useElementSize(imageElement);

function roundUp(num: number) {
  return num ? Math.ceil(num / 100) * 100 : DEFAULT_THUMBNAIL_SIZE;
}

const srcPath = computed(() => {
  console.log(product);
  const biggestParam =
    width.value > height.value
      ? `width=${roundUp(width.value)}`
      : `height=${roundUp(height.value)}`;
  return `${product.value.cover?.media.url}?${biggestParam}&fit=crop,smart`;
});
</script>
<template>
  <div class="cursor-pointer bg-white transition duration-300">
    <div class="flex-none overflow-hidden">
      <NuxtImg
        ref="imageElement"
        data-testid="layout-search-suggest-image"
        :src="srcPath"
        class="aspect-square object-cover"
        :alt="getTranslatedProperty(product, 'name')"
      />
    </div>
    <div
      class="mt-2 flex grow items-center justify-between gap-2 overflow-hidden"
    >
      <div
        data-testid="layout-search-suggest-name"
        class="text-dark overflow-hidden text-ellipsis whitespace-nowrap font-bold"
      >
        {{ getTranslatedProperty(product, 'name') }}
      </div>
      <div class="flex-none text-right">
        <SharedPrice
          v-if="unitPrice"
          data-testid="layout-search-suggest-price"
          class="justify-end"
          :value="unitPrice"
        >
          <template #beforePrice>
            <span v-if="displayFrom">{{ $t('product.price.from') }}</span>
          </template>
        </SharedPrice>
        <ProductUnits
          data-testid="layout-search-suggest-units"
          :product="product"
          :show-content="false"
          class="text-3"
        />
      </div>
    </div>
  </div>
</template>
